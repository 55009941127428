<!-- test -->
<template>
  <div>
    <select-shop :loadingButton="loading" v-on:getData="getSummaryReport"></select-shop>
    <div v-if="isPermission">
      <CRow>
        <CCol md="4">
          <CCard class="shadow-sm">
            <CCardBody>
              <CCallout color="success">
                <h5 class="text-muted">{{ $t("sales") }}</h5>
                <div class="row">
                  <strong class="col-md-9 col-sm-9 h4 font-weight-normal text-success">
                    {{ grandTotal }}
                  </strong>
                  <div class="col-md-3 col-sm-3 text-right text-success">
                    <CIcon size="3xl" name="cil-money" />
                  </div>
                </div>
              </CCallout>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="4">
          <CCard class="shadow-sm">
            <CCardBody>
              <CCallout color="info">
                <h5 class="text-muted">{{ $t("profit") }}</h5>
                <div class="row">
                  <strong class="col-md-9 col-sm-9 h4 font-weight-normal text-info">
                    {{ netProfit }}
                  </strong>
                  <div class="col-md-3 col-sm-3 text-right text-info">
                    <CIcon size="3xl" name="cil-smile-plus" />
                  </div>
                </div>
              </CCallout>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="4">
          <CCard class="shadow-sm">
            <CCardBody>
              <CCallout color="danger">
                <h5 class="text-muted">{{ $t("discount") }}</h5>
                <div class="row">
                  <strong class="col-md-9 col-sm-9 h4 font-weight-normal text-danger">
                    {{ discountTotal }}
                  </strong>
                  <div class="col-md-3 col-sm-3 text-right text-danger">
                    <CIcon size="3xl" name="cil-tag" />
                  </div>
                </div>
              </CCallout>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
     
      <CRow v-if="loginwith === 'one-id'">
        <CCol md="7">
          <CCard class="shadow-sm">
            <CCardBody style="height: 250px">
              <CRow>
                <CCol sm="12" lg="12">
                  <h3 class="font-weight-normal">{{ $t("payment") }}</h3>
                  <CChartDoughnut :datasets="paymentChart.datasets" :labels="paymentChart.labels"
                    :options="doughnutOptions" />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="5">
          <CCard class="shadow-sm">
            <CCardBody style="height: 250px">
              <CRow>
                <CCol sm="12" lg="12">
                  <h3 class="font-weight-normal">{{ $t("billAmount") }}</h3>
                  <table style="width: 100%" class="mb-4">
                    <tr class="font-weight-normal h5">
                      <td>{{ $t("total") }}</td>
                      <td class="text-right text-success">
                        {{ billCount }} {{ $t("bill") }}
                      </td>
                    </tr>
                    <br />
                    <tr class="font-weight-normal h6">
                      <td>{{ $t("averageBill") }}</td>
                      <td class="text-right">{{ avgBill }}</td>
                    </tr>
                  </table>
                  <hr />
                  <table style="width: 100%" class="mb-4">
                    <tr>
                      <td class="font-weight-normal">{{ $t("cancel") }}</td>
                      <td class="text-right text-danger">
                        {{ cancelBillCount }} {{ $t("bill") }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-normal">{{ $t("voidbillTotal") }}</td>
                      <td class="text-right text-danger">
                        {{ cancelBillTotal }}
                      </td>
                    </tr>
                  </table>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol md="4">
          <CCard class="shadow-sm">
            <CCardBody style="height: 230px">
              <CRow>
                <CCol sm="12" lg="12">
                  <h3 class="font-weight-normal text-left" style="margin-left: 20px">
                    {{ $t("payment") }}
                  </h3>
                  <CChartDoughnut :datasets="paymentChart.datasets" :labels="paymentChart.labels"
                    :options="doughnutOptions" />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="3">
          <CCard class="shadow-sm">
            <CCardBody style="height: 230px">
              <CRow>
                <CCol sm="12" lg="12">
                  <h4 class="font-weight-normal">{{ $t("billAmount") }}</h4>
                  <table style="width: 100%" class="mt-3">
                    <tr class="font-weight-normal h5">
                      <td>{{ $t("total") }}</td>
                      <td class="text-right text-success">
                        {{ billCount }} {{ $t("bill") }}
                      </td>
                    </tr>

                    <tr class="font-weight-normal h6">
                      <td>{{ $t("averageBill") }}</td>
                      <td class="text-right">{{ avgBill }}</td>
                    </tr>
                  </table>
                  <hr />
                  <table style="width: 100%" class="font-weight-normal">
                    <tr>
                      <td>{{ $t("cancel") }}</td>
                      <td class="text-right text-danger">
                        {{ cancelBillCount }} {{ $t("bill") }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("voidbillTotal") }}</td>
                      <td class="text-right text-danger">
                        {{ cancelBillTotal }}
                      </td>
                    </tr>
                  </table>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="5">
          <CCard class="shadow-sm">
            <CCardBody style="height: 230px">
              <CRow>
                <CCol sm="12" md="12">
                  <table style="width: 100%">
                    <tr>
                      <td>{{ $t("netAmount") }}</td>
                      <td class="text-right text-dark">{{ netAmount }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("discountAmount") }}</td>
                      <td class="text-right text-danger">-{{ discountAmount }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("netTotalIncVAT") }}</td>
                      <td class="text-right text-dark">{{ netTotalIncVAT }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("netTotalNonVAT") }}</td>
                      <td class="text-right text-dark">{{ netTotalNonVAT }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("vatAmount") }} 7%</td>
                      <td class="text-right text-dark">{{ vatAmount }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t("serviceCharge") }}</td>
                      <td class="text-right text-dark">{{ serviceCharge }}</td>
                    </tr>
                    <br />
                    <tr class="font-weight-normal h5">
                      <td>{{ $t("grandTotal") }}</td>
                      <td class="text-right text-success">
                        {{ grandTotal }}
                      </td>
                    </tr>
                  </table>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-if="summarySKUSuccess == true">
        <CCol md="4">
          <CCard class="shadow-sm">
            <CCardBody>
              <CCallout color="warning">
                <h5 class="text-muted">{{ $t("inventoryQuantity") }}</h5>
                <div class="row">
                  <strong class="col-md-9 col-sm-9 h4 font-weight-normal text-warning">
                    {{ onhandQty }} {{ $t('itemQty') }}
                  </strong>
                  <div class="col-md-3 col-sm-3 text-right text-warning mt-2">
                    <i class="fi fi-rr-box-open" style="font-size: 3rem;"></i>
                  </div>
                </div>
              </CCallout>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="4">
          <CCard class="shadow-sm">
            <CCardBody>
              <CCallout color="info">
                <h5 class="text-muted">{{ $t("totalCostValue") }}</h5>
                <div class="row">
                  <strong class="col-md-9 col-sm-9 h4 font-weight-normal text-info">
                    {{ totalCost }} {{ $t('baht') }}
                  </strong>
                  <div class="col-md-3 col-sm-3 text-right text-info mt-2">
                    <i class="fi fi-rr-coins" style="font-size: 3rem;"></i>
                  </div>
                </div>
              </CCallout>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="4">
          <CCard class="shadow-sm">
            <CCardBody>
              <CCallout color="success">
                <h5 class="text-muted">{{ $t("totalSalesValue") }}</h5>
                <div class="row">
                  <strong class="col-md-9 col-sm-9 h4 font-weight-normal text-success">
                    {{ totalSales }} {{ $t('baht') }}
                  </strong>
                  <div class="col-md-3 col-sm-3 text-right text-success mt-2">
                    <i class="fi fi-rr-badge-dollar" style="font-size: 3rem;"></i>
                  </div>
                </div>
              </CCallout>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-if="businessType === 1">
        <CCol lg="6" md="12" sm="12">
          <CCard class="shadow-sm">
            <CCardBody>
              <CRow>
                <CCol sm="12" md="12" lg="12">
                  <CCardHeader class="d-flex justify-content-between align-items-center mb-0" >
                    <h3 class="card-title mb-0 font-weight-normal">
                      Delivery Channels
                    </h3>
                    <div class="">
                      <CButton @click="$router.push('/report/salesChannels')" block color="success" style="width: 100px">
                        <div class="d-flex align-items-center justify-content-center">
                          <div style="margin-right: 4px; margin-top: 4px">
                            <i class="fi fi-rr-info"></i>
                          </div>
                          {{ $t("seemore") }}
                        </div>
                      </CButton>
                    </div>
                  </CCardHeader>
                  <CCardBody >
                   <DeliveryReport  ref="getSaleChannelsbReportData"></DeliveryReport>
                  </CCardBody>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol  lg="6" md="12" sm="12">
          <CCard class="shadow-sm">
            <CCardBody>

            <CRow>
              <CCol sm="12" md="12" lg="12">
                  <CCardHeader class="d-flex justify-content-between align-items-center mb-0" >
                    <h3 class="card-title mb-0 font-weight-normal">
                        <img src="/img/delivery/grab.png" class="rounded-circle"
                      style="border-radius: 3px; width: 35px;" /> Grab
                    </h3>
                    <div class="">
                      <CButton @click="$router.push('/grabdashboard')" block color="success" style="width: 100px">
                        <div class="d-flex align-items-center justify-content-center">
                          <div style="margin-right: 5px; margin-top: 4px">
                            <i class="fi fi-rr-info"></i>
                          </div>
                          {{ $t("seemore") }}
                        </div>
                      </CButton>
                    </div>
                  </CCardHeader>
                  <CCardBody>
                    <!-- <access-grab></access-grab> -->
                    <GrabReport ref="getGrabReportData" ></GrabReport>
                </CCardBody>
            </CCol>
          </CRow>
        </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-if="businessType === 1 || businessType === 2">
        <CCol col="12">
          <CCard class="shadow-sm">
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <CCardHeader class="d-flex justify-content-between align-items-center">
                    <h3 class="col-6 card-title mb-0 font-weight-normal">
                      {{ $t("tableAmount") }}
                    </h3>
                    <div>
                      <CButton @click="$router.push('/report/table')" block color="success" style="width: 100px">
                        <div class="row d-flex justify-content-center mt-1">
                          <div style="margin-top: 4px; margin-right: 5px">
                            <i class="fi fi-rr-info"></i>
                          </div>
                          {{ $t("seemore") }}
                        </div>
                      </CButton>
                    </div>
                  </CCardHeader>
                </CCol>
              </CRow>
              <br />
              <CRow >
                <CCol sm="12" lg="12">
                  <CRow>
                    <CCol class="vertical verticalBottom mb-3 " lg="3" col="6">
                      <div class="text-center mb-2">{{ $t("avgItemsPerBill") }}</div>
                      <div class="text-center text-success h5">
                        {{ tableSummaryItems }} {{ $t("itemperbill") }}
                      </div>
                      <div class="text-center ">
                        {{ $t("totalItems") }} {{ this.tableItems }} {{ $t("item") }}
                      </div>
                    </CCol>
                    <CCol  class="verticalCol verticalBottom mb-3" lg="3" col="6">
                      <div class="text-center mb-2">{{ $t("avgNumCustPerBill") }}</div>
                      <div class="text-center text-success h5">
                        {{ tableSummaryNumCust }} {{ $t("personperbill") }}
                      </div>
                      <div class="text-center">
                        {{ $t("numCust") }} {{ this.tableNumCust }} {{ $t("person") }}
                      </div>
                    </CCol>
                    <br/>
                    <CCol class="vertical mb-3" lg="3" col="6">
                      <div class="text-center mb-2">{{ $t("tableUsage") }}</div>
                      <div class="text-center text-success h5">
                        {{ tableSummaryBill }}
                        {{ $t("timeperday") }}
                      </div>
                      <div class="text-center">
                        {{ $t("avgBillPerDay") }} {{ this.tableBill }} {{ $t("times") }}
                      </div>
                    </CCol>
                    <CCol class="mb-3" lg="3" col="6">
                      <div class="text-center mb-2">{{ $t("avgDurationPerBill") }}</div>
                      <div class="text-center text-success h5">
                        {{ tableHoursSummary }} {{ $t("hours") }} {{ tableMinsSummary }} {{ $t("seconds") }} {{
                          $t('tableperbill') }}
                      </div>
                      <div class="text-center">
                        {{ $t("totalTime") }} {{ this.tableTime }} {{ $t("seconds") }}
                      </div>
                    </CCol>
                  </CRow>
                  <!-- <hr />
                  <CRow>
                    <CCol class="vertical" col="6">
                      <div class="text-center mb-2">{{ $t("tableUsage") }}</div>
                      <div class="text-center text-success h5">
                        {{ tableSummaryBill }}
                        {{ $t("timeperday") }}
                      </div>
                      <div class="text-center">
                        {{ $t("avgBillPerDay") }} {{ this.tableBill }} {{ $t("times") }}
                      </div>
                    </CCol>
                    <CCol col="6">
                      <div class="text-center mb-2">{{ $t("avgDurationPerBill") }}</div>
                      <div class="text-center text-success h5">
                        {{ tableHoursSummary }} {{ $t("hours") }} {{ tableMinsSummary }} {{ $t("seconds") }} {{
                          $t('tableperbill') }}
                      </div>
                      <div class="text-center">
                        {{ $t("totalTime") }} {{ this.tableTime }} {{ $t("seconds") }}
                      </div>
                    </CCol>
                  </CRow> -->
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CCard v-if="summaryByMonth.length > 1" class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol >
              <CCardHeader class="d-flex justify-content-between align-items-center mb-0" >
                <h3 id="traffic" class="card-title mb-0 font-weight-normal">
                {{ $t("salesByMonth") }}
              </h3>
                  </CCardHeader>
                  <CCardBody>
                    <CChartBar style="height: 300px; margin-top: 40px" :datasets="byMonthDatasets.datasets"
                    :labels="byMonthDatasets.labels" :options="byMonthOptions" />
                </CCardBody>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard v-else-if="summaryByMonth.length === 1 && startDate !== endDate" class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol sm="5">
              <h3 id="traffic" class="card-title mb-0 font-weight-normal">
                {{ $t("salesByDaily") }}
              </h3>
              <p>{{ startDate }} - {{ endDate }}</p>
            </CCol>
          </CRow>
          <CChartLine :datasets="byDateDatasets.datasets" :labels="byDateDatasets.labels" :options="byDateOptions" />
        </CCardBody>
      </CCard>

      <CCard v-else-if="summaryByMonth.length === 1 && startDate === endDate" class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol sm="5">
              <h3 id="traffic" class="card-title mb-0 font-weight-normal">
                {{ $t("salesBydate") }}
              </h3>
            </CCol>
          </CRow>
          <CChartBar style="height: 300px; margin-top: 40px" :datasets="byDailyDatasets.datasets"
            :labels="byDailyDatasets.labels" :options="byDailyOptions" />
        </CCardBody>
      </CCard>

      <CRow>
        <CCol md="6">
          <CCard class="shadow-sm">
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <CCardHeader class="d-flex justify-content-between align-items-center">
                    <h3 id="traffic" class="col-6 card-title mb-0 font-weight-normal">
                      {{ $t("bestSellers") }}
                    </h3>
                    <div>
                      <CButton @click="$router.push('/report/sku')" block color="success" style="width: 100px">
                        <div class="d-flex align-items-center justify-content-center">
                          <div style="margin-right: 4px; margin-top: 4px">
                            <i class="fi fi-rr-info"></i>
                          </div>
                          {{ $t("seemore") }}
                        </div>
                      </CButton>
                    </div>
                  </CCardHeader>

                  <br />
                  <CChartDoughnut v-if="netTotal != '0.00'" :datasets="topProducts.datasets"
                    :labels="topProducts.labels" />
                  <CCard>
                    <CCardBody>
                      <CRow>
                        <CCol sm="12" lg="12">
                          <CDataTable style="white-space: nowrap" :items="itemsProduct" :fields="fieldsProduct" hover
                            pagination border>
                            <template #no-items-view>
                              <div class="text-center my-5">
                                <h4 style="color: #ced2d8">
                                  {{ $t("noItem") }}
                                </h4>
                              </div>
                            </template>
                          </CDataTable>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="6">
          <CCard class="shadow-sm">
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <CCardHeader class="d-flex justify-content-between align-items-center">
                    <h3 id="traffic" class="col-8 card-title mb-0 font-weight-normal">
                      {{ $t("bestSellerstype") }}
                    </h3>
                    <div>
                      <CButton @click="$router.push('/report/category')" block color="success" style="width: 100px">
                        <div class="d-flex align-items-center justify-content-center">
                          <div style="margin-right: 3px; margin-top: 4px">
                            <i class="fi fi-rr-info"></i>
                          </div>
                          {{ $t("seemore") }}
                        </div>
                      </CButton>
                    </div>
                  </CCardHeader>
                  <br />
                  <CChartDoughnut v-if="netTotal != '0.00'" :datasets="topCategories.datasets"
                    :labels="topCategories.labels" />
                  <CCard>
                    <CCardBody>
                      <CRow>
                        <CCol sm="12" lg="12">
                          <CDataTable style="white-space: nowrap" :items="categoryItems" :fields="fieldsCategories"
                            hover pagination border>
                            <template #no-items-view>
                              <div class="text-center my-5">
                                <h4 style="color: #ced2d8">
                                  {{ $t("noItem") }}
                                </h4>
                              </div>
                            </template>
                          </CDataTable>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <silomconnect v-if="silomconnect !== 'true'"></silomconnect>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CChartLine, CChartDoughnut, CChartBar, CChartPolarArea } from "@coreui/vue-chartjs";
import { CWidgetProgressIcon, CWidgetSimple } from "@coreui/vue"
import { getColor, hexToRgba } from "@coreui/utils/src";
import axios from "../services/service";
import local from "../services/local";
import moment from "moment";
import util from "../util/util";
import silomconnect from "../containers/SilomConnectQR";
import GrabReport from "./report/GrabReportForDB.vue"
import DeliveryReport from "./report/SalesChannelForDB.vue"

import permis from "../util/permission";
import { fetchTableSummary } from "../controller/report/tableController";

export default {
  components: {
    CChartLine,
    CChartDoughnut,
    CChartBar,
    CChartPolarArea,
    CWidgetProgressIcon,
    CWidgetSimple,
    silomconnect,
    GrabReport,
    DeliveryReport
  },
  data() {
    return {
      data: [],
      summary: {},
      summaryByDate: [],
      summaryByHour: [],
      summaryByMonth: [],
      payment: [],
      list: [],
      product: [],
      categories: [],
      loading: true,
      getIdshop: "",
      isLoading: false,
      tableSummary: null,
      tableSummaryBill: 0,
      tableSummaryItems: 0,
      tableSummaryNumCust: 0,
      tableSummaryDuration: 0,
      tableItems: 0,
      tableTime: 0,
      tableNumCust: 0,
      tableBill: 0,
      shop: null,
      bussiness: null,
      tableHoursSummary: 0,
      tableMinsSummary: 0,
      onhandQty: 0,
      totalCost: 0,
      totalSales: 0,
      summarySKUSuccess: false,
      salesChannel: []
    };
  },
  async created() {

    this.$store.dispatch("getShopSetting");
    this.getSummaryReport();
    await this.loadData();
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
    await this.getSKUStock()

  },
  async mounted() {


    await this.loadData()
    this.getSKUStock()
  },
  computed: {
    ...mapGetters(["shops", "date", "users", "isOwner"]),
    isPermission() {
      return permis.findPermissionRead("dashboard", this.$route.path);
    },
    silomconnect() {
      return localStorage.getItem("silomconnect");
    },
    token() {
      return this.$store.getters.getToken;
    },
    currentPlan() {
      return this.users.currentPlan;
    },
    businessType() {
      this.shop = this.shops.find((i) => this.shopObjectId === i.objectId);
      if (this.shop) {
        this.business = this.shop.businessType;
      }
      return this.business;
    },
    loginwith() {
      return localStorage.getItem("loginwith");
    },
    uid() {
      return `${localStorage.getItem("shopsUid")}`;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    billCount() {
      return this.summary.billCount || 0;
    },
    avgBill() {
      return util.convertCurrency(this.summary.avgPerBill || 0);
    },
    cancelBillCount() {
      return this.summary.cancelBillCount || 0;
    },
    cancelBillTotal() {
      return util.convertCurrency(this.summary.cancelBillTotal || 0);
    },
    netTotal() {
      return util.convertCurrency(this.summary.netTotal || 0);
    },
    netProfit() {
      return util.convertCurrency(this.summary.netProfit || 0);
    },
    discountAmount() {
      return util.convertCurrency(this.summary.discountAmount || 0);
    },
    discountTotal() {
      return util.convertCurrency(this.summary.discountTotal || 0);
    },
    grandTotal() {
      return util.convertCurrency(this.summary.grandTotal || 0);
    },
    itemDisCount() {
      return util.convertCurrency(this.summary.itemDiscount || 0);
    },
    serviceCharge() {
      return util.convertCurrency(this.summary.serviceCharge || 0);
    },
    netAmountNonVAT() {
      return util.convertCurrency(this.summary.netAmountNonVAT || 0);
    },
    netAmountIncVAT() {
      return util.convertCurrency(this.summary.netAmountIncVAT || 0);
    },
    netTotalNonVAT() {
      return util.convertCurrency(this.summary.netTotalNonVAT || 0);
    },
    netTotalIncVAT() {
      return util.convertCurrency(this.summary.netTotalIncVAT || 0);
    },
    netAmount() {
      return util.convertCurrency(this.summary.netAmount || 0);
    },
    vatAmount() {
      return util.convertCurrency(this.summary.vatAmount || 0);
    },
    startDate() {
      return moment(String(this.date.start)).format("DD/MM/YYYY");
    },
    endDate() {
      return moment(String(this.date.end)).format("DD/MM/YYYY");
    },
    fieldsProduct() {
      return [
        {
          key: "id",
          label: this.$i18n.t("number"),
          _style: "width:5%",
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "productName",
          label: this.$i18n.t("productName"),
          _style: "width:40%",
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "quantity",
          label: this.$i18n.t("quantity"),
          _classes: "text-right font-weight-normal text-dark",
        },
        {
          key: "total",
          label: this.$i18n.t("sales"),
          _classes: "text-right font-weight-normal text-dark",
        },
      ];
    },
    itemsProduct() {
      let products = this.product || [];
      let objects = [];
      const count = products.length >= 10 ? 10 : products.length;
      for (let i = 0; i < count; i++) {
        const product = products[i];
        objects.push({
          id: i + 1,
          productName: product.productName || "",
          quantity: product.quantity || 0,
          total: util.convertCurrency(product.total || 0),
        });
      }
      return objects;
    },
    fieldsCategories() {
      return [
        {
          key: "id",
          label: this.$i18n.t("number"),
          _style: "width:5%",
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "categoryName",
          label: this.$i18n.t("categoryName"),
          _style: "width:40%",
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "quantity",
          label: this.$i18n.t("quantity"),
          _classes: "text-right font-weight-normal text-dark",
        },
        {
          key: "total",
          label: this.$i18n.t("sales"),
          _classes: "text-right font-weight-normal text-dark",
        },
      ];
    },
    categoryItems() {
      const categories = this.categories || [];
      let objects = [];
      const count = categories.length >= 10 ? 10 : categories.length;
      for (let i = 0; i < count; i++) {
        const category = categories[i];
        objects.push({
          id: i + 1,
          categoryName: category.categoryName || "",
          quantity: category.quantity || 0,
          total: util.convertCurrency(category.total || 0),
        });
      }
      return objects;
    },
    byDailyDatasets() {
      const brandSuccess = getColor("success") || "#4dbd74";

      const byHour = this.summaryByHour;
      const data = [];
      const labels = [];

      for (let i in byHour) {
        data.push(byHour[i].total);
        labels.push(byHour[i].hour + ".00");
      }

      if (data.length === 0) {
        return {
          labels: [
            "00.00",
            "01.00",
            "02.00",
            "03.00",
            "04.00",
            "05.00",
            "06.00",
            "07.00",
            "08.00",
            "09.00",
            "10.00",
            "11.00",
            "12.00",
            "13.00",
            "14.00",
            "15.00",
            "16.00",
            "17.00",
            "18.00",
            "19.00",
            "20.00",
            "21.00",
            "22.00",
            "23.00",
          ],
          datasets: [
            {
              backgroundColor: hexToRgba(brandSuccess, 30),
              borderColor: brandSuccess,
              pointHoverBackgroundColor: brandSuccess,
              borderWidth: 2,
              data: [],
              lineTension: 0,
              spanGaps: true,
            },
          ],
        };
      } else {
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: hexToRgba(brandSuccess, 30),
              borderColor: brandSuccess,
              pointHoverBackgroundColor: brandSuccess,
              borderWidth: 2,
              data: data,
              lineTension: 0,
              spanGaps: true,
            },
          ],
        };
      }
    },
    byDailyOptions() {
      const byHour = this.summaryByHour;
      const data = [];
      let max = 0;

      for (let i in byHour) {
        data.push(byHour[i].total);
      }

      if (data.length === 0) {
        max = 100;
      } else {
        max = Math.max(...data);
      }

      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: Math.ceil(250 / 5),
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      };
    },
    byDateDatasets() {
      const brandSuccess = getColor("success") || "#4dbd74";

      const byDayList = this.summaryByDate;
      const data = [];
      const label = [];

      for (let i in byDayList) {
        data.push(byDayList[i].total);
        label.push(byDayList[i].dateString);
      }

      if (data.length === 0) {
        let xlabel = [
          moment(String(this.date.start)).format("YYYY-MM-DD"),
          moment(String(this.date.end)).format("YYYY-MM-DD"),
        ];

        return {
          labels: xlabel,
          datasets: [
            {
              backgroundColor: hexToRgba(brandSuccess, 30),
              borderColor: brandSuccess,
              pointHoverBackgroundColor: brandSuccess,
              borderWidth: 2,
              data: [0, 0],
              lineTension: 0,
              spanGaps: true,
            },
          ],
        };
      } else {
        return {
          labels: label,
          datasets: [
            {
              backgroundColor: hexToRgba(brandSuccess, 30),
              borderColor: brandSuccess,
              pointHoverBackgroundColor: brandSuccess,
              borderWidth: 2,
              data: data,
              lineTension: 0,
              spanGaps: true,
            },
          ],
        };
      }
    },
    byDateOptions() {
      const byDayList = this.summaryByDate;
      const data = [];
      let max = 0;

      for (let i in byDayList) {
        data.push(byDayList[i].total);
      }

      if (data.length === 0) {
        max = 1000;
      } else {
        max = Math.max(...data);
      }

      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                displayFormats: { day: "YYYY-MM-DD" },
                unit: "day",
              },
              ticks: {
                min: moment(String(this.date.start)).format("YYYY-MM-DD"),
                max: moment(String(this.date.end)).format("YYYY-MM-DD"),
              },
              gridLines: { drawOnChartArea: false },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: Math.ceil(250 / 5),
                min: 0,
              },
              gridLines: { display: true },
            },
          ],
        },
      };
    },
    byMonthDatasets() {
      const brandSuccess = getColor("success") || "#4dbd74";

      const byMonthList = this.summaryByMonth;
      const data = [];
      const label = [];

      for (let i in byMonthList) {
        data.push(byMonthList[i].total);
        label.push(byMonthList[i].dateString);
      }

      return {
        labels: label,
        datasets: [
          {
            backgroundColor: hexToRgba(brandSuccess, 30),
            borderColor: brandSuccess,
            pointHoverBackgroundColor: brandSuccess,
            borderWidth: 2,
            data: data,
            lineTension: 0,
            spanGaps: true,
          },
        ],
      };
    },
    byMonthOptions() {
      const byMonthList = this.summaryByMonth;
      const data = [];
      let max = 0;

      for (let i in byMonthList) {
        data.push(byMonthList[i].total);
      }

      if (data.length === 0) {
        max = 1000;
      } else {
        max = Math.max(...data);
      }

      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                displayFormats: { day: "YYYY-MM" },
                unit: "month",
              },
              gridLines: { drawOnChartArea: false },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: Math.ceil(250 / 5),
                min: 0,
              },
              gridLines: { display: true },
            },
          ],
        },
      };
    },
    doughnutOptions() {
      return {
        legend: { position: "right" },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    PolarOptions() {
      return {
        legend: { position: "right" },
        responsive: true,
        height: 200,
        maintainAspectRatio: false,
      };
    },
    topProducts() {
      const products = this.product || [];
      const data = [];
      const topLables = [];
      const topValues = [];

      const count = products.length >= 5 ? 5 : products.length;

      for (let i = 0; i < count; i++) {
        const product = products[i];
        const object = {
          quantity: product.quantity,
          productName: product.categoryName,
        };
        data.push(object);
        topValues.push(product.quantity);
        topLables.push(product.productName);
      }

      return {
        labels: topLables,
        datasets: [
          {
            backgroundColor: ["#F29089", "#5CBA96", "#4CB2D4", "#F6CE3B", "#A9A7D6"],
            data: topValues,
          },
        ],
      };
    },
    topCategories() {
      const categories = this.summary.topCategories || [];
      const data = [];
      const topLables = [];
      const topValues = [];

      const count = categories.length >= 5 ? 5 : categories.length;

      for (let i = 0; i < count; i++) {
        const category = categories[i];
        const object = {
          quantity: category.quantity,
          categoryName: category.categoryName,
        };
        data.push(object);
        topValues.push(category.quantity);
        topLables.push(category.categoryName);
      }

      return {
        labels: topLables,
        datasets: [
          {
            backgroundColor: ["#F29089", "#5CBA96", "#4CB2D4", "#F6CE3B", "#A9A7D6"],
            data: topValues,
          },
        ],
      };
    },
    paymentChart() {
      const payments = this.payment || [];
      const data = [];
      const labels = [];
      for (let i = 0; i < payments.length; i++) {
        const payment = payments[i];
        if (payment.amount > 0) {
          data.push(payment.amount);
          if (payment.label === "cash") {
            labels.push(this.$i18n.t("paymentType0"));
          } else if (payment.label === "credit") {
            labels.push(this.$i18n.t("paymentType1"));
          } else if (payment.label === "coupon") {
            labels.push(this.$i18n.t("paymentType2"));
          } else if (payment.label === "promtpay") {
            labels.push(this.$i18n.t("paymentType4"));
          } else if (payment.label === "alipay") {
            labels.push(this.$i18n.t("paymentType5"));
          } else if (payment.label === "delivery") {
            labels.push(this.$i18n.t("paymentType7"));
          } else if (payment.label === "custom payment") {
            labels.push(this.$i18n.t("paymentType10"));
          } else if (payment.label === "online order") {
            labels.push(this.$i18n.t("paymentType8"));
          } else if (payment.label === "line pay") {
            labels.push(this.$i18n.t("paymentType9"));
          } else if (payment.label === "KBank Qr Payment") {
            labels.push(this.$i18n.t("paymentType12"));
          } else if (payment.label.toUpperCase === "EDC") {
            labels.push(this.$i18n.t("paymentType14"));
          }
        }
      }

      if (data.length === 0) {
        return {
          labels: [],
          datasets: [
            {
              backgroundColor: ["#5CBA96"],
              data: [0],
              borderWidth: 1,
            },
          ],
        };
      } else {
        return {
          labels: labels,
          datasets: [
            {
              backgroundColor: [
                "#5CBA96",
                "#F29089",
                "#4CB2D4",
                "#F6CE3B",
                "#A9A7D6",
                "#BA5C80",
                "#FBE3B0",
                "#D19992",
                "#DAF7A6",
                "#B689B2",
              ],
              data: data,
              borderWidth: 1,
            },
          ],
        };
      }
    },
    
  },
  methods: {
    ...util,
    getSalesChannelsReport(page = 1) {
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");
      let sortOrder = "";
      let sort = "";
      if (this.sortdata === "name") {
        sort = "name";
      } else if (
        this.sortdata === "sortBySalesMin" ||
        this.sortdata === "sortBySalesMax"
      ) {
        sort = "gpGrandTotal";
      } else if (
        this.sortdata === "sortByBillAmountMin" ||
        this.sortdata === "sortByBillAmountMax"
      ) {
        sort = "bill";
      } else {
        sort = this.sortdata;
      }
      if (this.sortdata === "sortBySalesMin" || this.sortdata === "sortByBillAmountMin") {
        sortOrder = "asc";
      } else if (
        this.sortdata === "sortBySalesMax" ||
        this.sortdata === "sortByBillAmountMax"
      ) {
        sortOrder = "desc";
      } else {
        sortOrder = "asc";
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        sortattr: sort,
        sortorder: sortOrder,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };
      this.loading = true;
      axios({
        url: "/receipt/v1.0/sellchannelsum/" + uid + "/data",
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((res) => {
          console.log(res);
          
         
   
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSummaryReport() {
      this.loading = false;
      let shop = this.shopObjectId;
      const uid = `${localStorage.getItem("shopsUid")}`;
      let shopObjectId = "";
      if (shop === "null") {
        shopObjectId = this.getIdshop;
      } else {
        shopObjectId = this.shopObjectId;
      }
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };
      axios({
        url: "/dashboard/v1.0/getdaysummaryreport/" + uid,
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((res) => {
          const response = res.data || {};
          const data = response.data || {};
          const summary = data.summary || {};

          this.data = data;
          this.list = data.list || [];

          this.summary = summary;
          this.payment = summary.payment || [];
          this.summaryByHour = summary.summaryByHour || [];
          this.summaryByDate = summary.summaryByDate || [];
          this.summaryByMonth = summary.summaryByMonth || [];
          this.product = summary.topProducts || [];
          this.categories = summary.topCategories || [];
          this.loading = true;
          this.getSKUStock()
          if(this.isGrabOrderEnabled ){
              this.$refs.getGrabReportData.getGrabReport()
          }
          this.$refs.getSaleChannelsbReportData.getSalesChannelsSum()
          this.loadData()
         
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSKUStock() {
      this.isLoading = true;
      let shop = this.shopObjectId;
      const uid = `${localStorage.getItem("shopsUid")}`;
      let shopObjectId = "";
      if (shop === "null") {
        shopObjectId = this.getIdshop;
      } else {
        shopObjectId = this.shopObjectId;
      }
      const params = {
        shopObjectId: shopObjectId,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };
      axios({
        url: "/dashboard/v1.0/summary/skustock",
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((res) => {
          if (res.data.data._id === '0') {
            this.summarySKUSuccess = false
          } else {
            this.onhandQty = util.formatNumber(res.data.data.onhandQty)
            this.totalCost = util.formatNumber(res.data.data.totalCost)
            this.totalSales = util.formatNumber(res.data.data.total)
            this.isLoading = false
            this.summarySKUSuccess = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadData() {
      this.isLoading = true;
      try {
        this.tableSummary = await fetchTableSummary(this.date.start, this.date.end);
        this.isLoading = false;
        this.loadingButton = true;
        if (this.tableSummary != null) {
          if (this.tableSummary.totalBill === 0) {
            this.tableSummaryItems = 0
            this.tableSummaryNumCust = 0
            this.tableSummaryDuration = 0
            this.tableSummaryฺBill = 0
            this.tableItems = 0
            this.tableTime = 0
            this.tableNumCust = 0
            this.tableBill = 0
            this.tableHoursSummary = 0
            this.tableMinsSummary = 0
          } else {
            this.tableSummaryDuration = this.tableSummary.totalAvgDurationPerTable
            this.tableSummaryItems = this.tableSummary.totalAvgItemsPerTable
            this.tableSummaryNumCust = this.tableSummary.totalAvgNumCustPerTable
            this.tableSummaryBill = this.tableSummary.avgTotalBill
            this.tableItems = this.tableSummary.totalItems
            this.tableTime = this.tableSummary.totalDuration
            this.tableNumCust = this.tableSummary.totalNumCust
            this.tableBill = this.tableSummary.totalBill
            this.tableHoursSummary = this.tableSummary.totalAvgHoursDurationPerTable
            this.tableMinsSummary = this.tableSummary.totalAvgMinsDurationPerTable
          }
        } else {
          this.isLoading = false;
          this.loadingButton = true;
        }
      } catch (error) {
        console.error("Error loading data:", error);
        this.isLoading = false;
        this.loadingButton = false;
      }
    },
  },
};
</script>
<style>
.vertical {
  /* float: left; */
  border-right: 1px solid rgba(0, 0, 21, 0.2);
}
.verticalCol {
  /* float: left; */
  border-right: 1px solid rgba(0, 0, 21, 0.2);
}

@media (max-width: 600px) {
  .verticalCol {
    /* float: left; */
    border-right: 0px solid rgba(0, 0, 21, 0.2);
  }
  .verticalBottom{
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 21, 0.2);
  }
}
</style>
