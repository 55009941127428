import { render, staticRenderFns } from "./heatmap.vue?vue&type=template&id=608be317&scoped=true&"
import script from "./heatmap.vue?vue&type=script&lang=js&"
export * from "./heatmap.vue?vue&type=script&lang=js&"
import style0 from "./heatmap.vue?vue&type=style&index=0&id=608be317&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608be317",
  null
  
)

export default component.exports