<template>
    <div>
         <div v-if="isGrabOrderEnabled">
                    <CRow>
                        <CCol lg="5" sm="6" >
                            <CRow >
                                <CCol lg="12" sm="12"  col="6">
                                    <div class="card"
                                        style="box-shadow: #0097a0 0px 0px 0px 2px; color: #0097a0; padding: 0px;">
                                        <div class="card-body text-center m-0" style="padding: 0px">
                                            <div class="text-muted small text-uppercase font-weight-bold">
                                                {{ $t("totalRevenue") }}
                                            </div>
                                            <div class="h3"> {{ totalAmountSuccess }} </div>

                                            <div class="row" >
                                                   
                                                   <div class="col vertical" >
                                                       <div class="col fs-5 fw-semibold"> {{totalAmountSuccess}}</div>
                                                       <div class="col text-uppercase text-body-secondary small"> {{ $t("success") }}</div>
                                                   </div>
                                                   <div class="col ">
                                                       <div class="col fs-5 fw-semibold"> {{totalAmountCancel}}</div>
                                                       <div class="col text-uppercase text-body-secondary small"> {{ $t("cancel") }}</div>
                                                   </div>
                                                </div>
                                        </div>
                                    </div>
                                </CCol>
                                <CCol lg="12" sm="12" col="6">
                                    <div class="card"
                                        style="box-shadow: #00a585 0px 0px 0px 2px; color: #00a585 ;padding: 3px;">
                                        <div class="card-body text-center" style="padding: 0px">
                                            <div class="text-muted small text-uppercase font-weight-bold"> {{ $t("orderTotal") }}</div>
                                            <div class="h3 "> {{ billTotal }} </div>
                                            <div class="row" >   
                                                   <div class="col vertical" >
                                                       <div class="col fs-5 fw-semibold">{{billSuccess}}</div>
                                                       <div class="col text-uppercase text-body-secondary small"> {{ $t("orderSuccess") }}</div>
                                                   </div>
                                                   <div  ></div>
                                                   <div class="col">
                                                       <div class="col fs-5 fw-semibold">{{billCancel}}</div>
                                                       <div class="col text-uppercase text-body-secondary small"> {{ $t("orderCancel") }}</div>
                                                   </div>
                                                </div>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol lg="7" sm="6" >
                            <CRow>
                                <CCol lg="12">
                                    <CCard style="box-shadow: 0 0 0 1px #e5e5e5;">
                                        <CCardBody>
                                            <h5>{{ $t("orderSummary") }}</h5>
                                            <div class="row mb-2">
                                                <CButton color="dark" class="col-4" style="font-size: smaller;border-radius: unset;" :class="[{active : isShowStatusSuccess === 'total'}]" variant="outline"   @click="showStatusSummary('total')">
                                                    {{ $t("allOrders") }}
                                                </CButton>  
                                                <CButton color="dark" class="col-4" style="font-size: smaller;    border-radius: unset;" :class="[{active : isShowStatusSuccess === 'success'}]" variant="outline"  @click="showStatusSummary('success')">
                                                    {{ $t("successOrders") }}
                                                </CButton>                                       
                                                <CButton color="dark" class="col-4"  style="font-size: smaller; border-radius: unset;" :class="[{active : isShowStatusSuccess === 'cancel'}]" variant="outline"    @click="showStatusSummary('cancel')">
                                                    {{ $t("cancelOrders") }}
                                                </CButton>
                                                                                            
                                            </div>
                                            <CRow>
                                                <CCol lg="12" style=" align-items: center;" v-if=" isShowStatusSuccess === 'total'">
                                                    <CChartBar :datasets="deliveryChart.datasets"
                                                        :labels="deliveryChart.chartLabels"
                                                        :options="deliveryChart.chartOptions"
                                                        style="height: 155px;align-items: center;" />
                                                </CCol>
                                                <CCol lg="12" style=" align-items: center;" v-if=" isShowStatusSuccess === 'success'">
                                                    <CChartBar :datasets="deliverySuccessChart.datasets"
                                                        :labels="deliverySuccessChart.chartLabels"
                                                        :options="deliverySuccessChart.chartOptions"
                                                        style="height: 155px;align-items: center;" />
                                                </CCol>
                                                <CCol lg="12" style=" align-items: center;" v-if=" isShowStatusSuccess === 'cancel'">
                                                    <CChartBar :datasets="deliveryChartCancel.datasets"
                                                        :labels="deliveryChart.chartLabels"
                                                        :options="deliveryChart.chartOptions"
                                                        style="height: 155px;align-items: center;" />
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            
                            </CRow>
                        </CCol>
                    </CRow>
             
        </div>
        <div v-else style="" class="bottom-aligned-container text-center"  >
            <CImg src="/img/delivery/grabPR.png" style="max-width: 100%; height: auto; display: block; margin: 0 auto; "/>
        </div> 
    
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import pos from "@/services/local";
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import { CWidgetProgressIcon, CWidgetSimple } from "@coreui/vue"
import { CChartDoughnut, CChartLine, CChartBar } from "@coreui/vue-chartjs";
import heatmap from './heatmap.vue';


export default {
    components: {
        Pagination,
        DataTable,
        CWidgetProgressIcon,
        CWidgetSimple,
        CChartDoughnut,
        CChartLine,
        CChartBar,
        heatmap,
       
    },
    data() {
        return {
            data: [],
            paymentdata: [],
            details: [],
            meta_data: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: null,
                itemCount: null,
            },
            loadingButton: true,
            loading: true,
            salesChannel: [],
            categories: [],
            product: [],
            summary: {},
            totalRevenue: '0.00',
            orderTotal : '0.00',
            totalAmountSuccess: '0.00',
            totalAmountCancel : '0.00',
            billCancel : 0,
            billSuccess : 0,
            billTotal : 0,
            dataAmount :[],
            ordertype : [],
            deliveryList : [],
            selfPicUpList : [],
            chartLabelsSummary : [],
            deliveryDataSummary : [],
            pickupDataSumary : [],
            orderfrequency : [],
            rejectDataSummary : [], 
            cancelDataSummary :[], 
            totalSuccessBills : [],
            summaryOrderCancelData:[],
            isShowStatusSuccess :  'success',
            isGrabOrderEnabled : false
        }
    },
    mounted() {
        // this.getStaticAmount()
        // this.$refs.getHourly.getHourReport() 
        // this.getOrderSummary()
        // this.getGrabTransaction()
    },
    computed: {
        ...mapGetters(['shops', 'date', 'users']),
        startDate() {
            return moment(String(this.date.start)).format("DD MMMM YYYY");
        },
        endDate() {
            return moment(String(this.date.end)).format("DD MMMM YYYY");
        },
        
        itemsProduct() {
            let products = this.product || [];
            let objects = [];
            const count = products.length >= 10 ? 10 : products.length;
            for (let i = 0; i < count; i++) {
                const product = products[i];
                objects.push({
                    id: i + 1,
                    productName: product.productName || "",
                    quantity: product.quantity || 0,
                    total: util.convertCurrency(product.total || 0),
                });
            }
            return objects;
        },
        categoryItems() {
            const categories = this.categories || [];
            let objects = [];
            const count = categories.length >= 10 ? 10 : categories.length;
            for (let i = 0; i < count; i++) {
                const category = categories[i];
                objects.push({
                    id: i + 1,
                    categoryName: category.categoryName || "",
                    quantity: category.quantity || 0,
                    total: util.convertCurrency(category.total || 0),
                });
            }
            return objects;
        },
        fieldsProduct() {
            return [
                {
                    key: "id",
                    label: this.$i18n.t("number"),
                    _style: "width:5%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "productName",
                    label: this.$i18n.t("productName"),
                    _style: "width:40%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "quantity",
                    label: this.$i18n.t("quantity"),
                    _classes: "text-right font-weight-normal text-dark",
                },
                {
                    key: "total",
                    label: this.$i18n.t("sales"),
                    _classes: "text-right font-weight-normal text-dark",
                },
            ];
        },

        deliveryChart() {
            
            return {
                
                chartLabels: this.chartLabelsSummary ,
                datasets: [
                    {
                        label: this.$i18n.t("orderTotal"),
                        backgroundColor: '#00737c',
                        data: this.totalBillSummary.map(({ bills }) => bills), // Inner ring values
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 10, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true, // Hide default legend
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const day = tooltipItem.label; // Day name
                                    const dataType = tooltipItem.dataset.label; // "Delivery" or "Pickup"
                                    const value = tooltipItem.raw; // Raw data for the day
                                    return [
                                        `${day} - ${dataType}:`,
                                        `Total Bills: ${value.bills}`,
                                        `Grand Total: $${value.totalGrandTotal}`,
                                    ].join("\n");
                                },
                            },
                        },
                    },
                },
            };
        },
        deliverySuccessChart() {
            
            return {
                
                chartLabels: this.chartLabelsSummary ,
                datasets: [
                    {
                        label: this.$i18n.t("deliveryOnDemand"),
                        backgroundColor: '#00887f',
                        data: this.deliveryDataSummary.map(({ bills }) => bills), // Inner ring values
                    },
                    {
                        label: this.$i18n.t("selfPickUpOnDemand"),
                        backgroundColor: "#cfd06c",
                        data: this.pickupDataSumary.map(({ bills }) => bills), // Outer ring values
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 10, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true, // Hide default legend
                        },
                        tooltip: {
                            formatter: (params) => {
                            if (!params.data) return "No data";
                            const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            const dayName = days[params.data[1]];
                            return `
                            <strong>Day:</strong> ${dayName} <br>
                            <strong>Hour:</strong> ${params.data[0]} <br>
                            <strong>Bills Total:</strong> ${params.data[2]}
                            `;
                        },
                        },
                    },
                },
            };
        },
        deliveryChartCancel(){
 
            return {
                
                chartLabels: this.chartLabelsSummary ,
                datasets: [
                    {
                        label: this.$i18n.t("reject"),
                        backgroundColor: '#079d76', 
                        data: this.rejectDataSummary.map(({ bills }) => bills), // Inner ring values
                    },
                    {
                        label: this.$i18n.t("cancel"),
                        backgroundColor: "#ffdd82",
                        data: this.cancelDataSummary.map(({ bills }) => bills), // Outer ring values
                    },
                ],
                chartOptions: {
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 10, // Doughnut hole size
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Hide default legend
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return `${tooltipItem.label}: ${tooltipItem}`;
                                },
                            },
                        },
                    },
                },
            };
        },
        isPermission() {
            // return permis.findPermissionRead('report', this.$route.path)
            return true
        },
        isExport() {
            return permis.findPermissionExport('report', this.$route.path)
        },
        uid() {
            return `${localStorage.getItem('shopsUid')}`
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        grabDonutCharts() {
            const success = this.billSuccess || 0;
            const cancel = this.billCancel || 0;
            const hasData = success > 0 || cancel > 0;
            return {

                // Data for the doughnut chart
                chartData: {
                    labels: hasData
                    ? [this.$i18n.t("success"), this.$i18n.t("cancel")]
                    : [this.$i18n.t("noData") || "No Data"], // Default label
                        datasets: [
                            {
                                data: hasData ? [success, cancel] : [1], // Default single slice
                                backgroundColor: hasData
                                    ? ["#94c063", "#ffdd82"] // Success and Cancel colors
                                    : ["#ebedef"], // Neutral color for no data
                                hoverBackgroundColor: hasData
                                    ? ["#51af65", "#cfd06c"] // Hover colors for success and cancel
                                    : ["#d0d3d4"], // Neutral hover color
                                borderWidth: 1,
                            },
                    ],
                },
                // Chart options
                chartOptions: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    if (!hasData) return this.$i18n.t("noData") || "No Data";
                                    const dataset = tooltipItem.dataset?.data || [];
                                    const value = dataset[tooltipItem.dataIndex] || 0;
                                    const total = dataset.reduce((sum, val) => sum + val, 0) || 1;
                                    const percentage = ((value / total) * 100).toFixed(2);
                                    const label = tooltipItem.label || "Unknown";
                                    return `${label}: ${value} (${percentage}%)`;
                                },
                            },
                        },
                        legend: {
                            position: "top", // Position of the legend
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                },
            };
        },
        fieldsCategories() {
            return [
                {
                    key: "id",
                    label: this.$i18n.t("number"),
                    _style: "width:5%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "categoryName",
                    label: this.$i18n.t("categoryName"),
                    _style: "width:40%",
                    _classes: "text-dark font-weight-normal",
                },
                {
                    key: "quantity",
                    label: this.$i18n.t("quantity"),
                    _classes: "text-right font-weight-normal text-dark",
                },
                {
                    key: "total",
                    label: this.$i18n.t("sales"),
                    _classes: "text-right font-weight-normal text-dark",
                },
            ];
        },
        fields() {
            return [
                {
                    key: "id",
                    label: this.$i18n.t("#"),
                    _classes: "text-center text-dark font-weight-normal",
                    _style: "width:5%",
                },
                {
                    key: "id",
                    label: this.$i18n.t("date"),
                    _classes: "text-center text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "order No",
                    label: "Order No.",
                    _classes: "text-center text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "bill",
                    label: this.$i18n.t("Order Type"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "bill",
                    label: this.$i18n.t("totalAmount"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "afterGP",
                    label: this.$i18n.t("discount"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:15%",
                },
                {
                    key: "bill",
                    label: this.$i18n.t("grandTotal"),
                    _classes: "text-right text-dark font-weight-normal",
                    _style: "width:10%",
                },
                {
                    key: "salesChannels",
                    //   label: this.$i18n.t("txtSalesChannels"),
                    label: "Items(Count)",
                    _classes: "text-left text-dark font-weight-normal ",
                    _style: "width:10%",
                },
                {
                    key: "status",
                    //   label: this.$i18n.t("txtSalesChannels"),
                    label: "Status",
                    _classes: "text-left text-dark font-weight-normal ",
                    _style: "width:10%",
                },
            ];
        },
        totalBillSummary(){

            const cancel = this.summaryOrderCancelData
            const success = this.totalSuccessBills

            const summary = cancel.map((cancelItem, index) => {
            const successItem = success[index];
            return {
                bills: cancelItem.bills + successItem.bills,
                totalGrandTotal: cancelItem.totalGrandTotal + successItem.totalGrandTotal,
            };
            });

            return summary
        }

    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getOrderShopSetting()
            }
        }
    },
    async created() {
        await this.getOrderShopSetting()
        await this.getStaticAmount()
        await this.getOrderSummary()
        await this.getCancelOrderSummary()
    },
    methods: {
        ...util,
        getOrderShopSetting(){
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`
            let params = {
                shopObjectId: shopObjectId,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/shop/ordershopsetting",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                if(res.status ==200){
                    let isGrabOrderEnabled  = res.data.data.isGrabOrderEnabled
                    if(isGrabOrderEnabled!== undefined && isGrabOrderEnabled !== null && isGrabOrderEnabled!==''){
                        this.isGrabOrderEnabled = isGrabOrderEnabled
                    }else{
                        this.isGrabOrderEnabled = false
                    }
                }else{
                    this.isGrabOrderEnabled = false
                }
                
            }).catch((error) => {
                    console.log(error);
            });
        },
        showStatusSummary(status){
            this.isShowStatusSuccess  = status
        },
        openTab(tabName, color) {
            this.activeTab = tabName;
        },
        getCancelOrderSummary(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/cancel",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {

                const dayNames = [this.$i18n.t("sun"), this.$i18n.t("mon"), this.$i18n.t("tues"), this.$i18n.t("wed"), this.$i18n.t("thur"), this.$i18n.t("fri"),this.$i18n.t("sat")];
                const chartData = Array(7).fill(0).map((_, index) => ({
                    day: dayNames[index],
                    rejectData: { bills: 0, totalGrandTotal: 0 }, // Separate bills and grandTotal for delivery
                    cancelData: { bills: 0, totalGrandTotal: 0 },   
                    totalBill : {bills : 0,totalGrandTotal: 0}

                }));

                let data = res.data.data;

                data.forEach(({ dayOfWeek, orderStatus, bill, grandTotal }) => {
                        const dayIndex = parseInt(dayOfWeek, 10) - 1; 
                        if (orderStatus === 'REJECT') {
                            chartData[dayIndex].rejectData.bills += bill;
                            chartData[dayIndex].rejectData.totalGrandTotal += grandTotal;
                        } else if (orderStatus === 'CANCEL') {
                            chartData[dayIndex].cancelData.bills += bill;
                            chartData[dayIndex].cancelData.totalGrandTotal += grandTotal;
                        }
                        chartData[dayIndex].totalBill.bills += bill;
                        chartData[dayIndex].totalBill.totalGrandTotal += grandTotal;
                });

                const chartLabels = chartData.map(({ day }) => day);
                const rejectData = chartData.map(({ rejectData }) => rejectData);
                const cancelData = chartData.map(({ cancelData }) => cancelData);
                const sumCancelData = chartData.map(({ totalBill }) => totalBill);

                this.cancelChartLabelsSummary = chartLabels;                
                this.rejectDataSummary = rejectData; // Contains structured data for delivery
                this.cancelDataSummary = cancelData;     
                this.summaryOrderCancelData = sumCancelData
        
            });
        },
        getGrabReport(){
            this.getStaticAmount() 
            this.getOrderSummary()
            this.getCancelOrderSummary()
        },
        getOrderSummary(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/ordertype",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                const dayNames = [this.$i18n.t("sun"), this.$i18n.t("mon"), this.$i18n.t("tues"), this.$i18n.t("wed"), this.$i18n.t("thur"), this.$i18n.t("fri"),this.$i18n.t("sat")];
                const chartData = Array(7).fill(0).map((_, index) => ({
                    day: dayNames[index],
                    delivery: { bills: 0, totalGrandTotal: 0 }, // Separate bills and grandTotal for delivery
                    pickup: { bills: 0, totalGrandTotal: 0 },  
                    totalBill : {bills : 0,totalGrandTotal: 0}

                }));

                let data = res.data.data;

                data.forEach(({ dayOfWeek, typeOfOrder, bill, grandTotal }) => {
                        const dayIndex = parseInt(dayOfWeek, 10) - 1; 
                        if (typeOfOrder === 4) {
                            chartData[dayIndex].delivery.bills += bill;
                            chartData[dayIndex].delivery.totalGrandTotal += grandTotal;
                        } else if (typeOfOrder === 3) {
                            chartData[dayIndex].pickup.bills += bill;
                            chartData[dayIndex].pickup.totalGrandTotal += grandTotal;
                        }
                        chartData[dayIndex].totalBill.bills += bill;
                        chartData[dayIndex].totalBill.totalGrandTotal += grandTotal;
                });

                const chartLabels = chartData.map(({ day }) => day);
                const deliveryData = chartData.map(({ delivery }) => delivery);
                const pickupData = chartData.map(({ pickup }) => pickup);
                const totalBill = chartData.map(({ totalBill }) => totalBill);


                this.chartLabelsSummary = chartLabels;
                this.deliveryDataSummary = deliveryData; // Contains structured data for delivery
                this.totalSuccessBills = totalBill
                this.pickupDataSumary = pickupData;     
        
            });
        },
        getStaticAmount(){
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
            const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

            let params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt : endAt,
                channel : '101',
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            pos({
                url: "/api/v3.0/sellingchannel/statistic/amount",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                
                let data = res.data.data;
                let billTotal = 0
                let totalRevenue = 0

                for (let i = 0; i < data.length; i++) {

                    if(data[i].receiptStatus === 'V'){
                        this.billCancel = data[i].bill
                        this.totalAmountCancel =  util.convertCurrency(data[i].grandTotal)    
                    }

                    if(data[i].receiptStatus === 'A'){
                        this.billSuccess = data[i].bill
                        this.totalAmountSuccess = util.convertCurrency(data[i].grandTotal)
                    } 

                    totalRevenue = totalRevenue + data[i].grandTotal
                    billTotal = billTotal + data[i].bill
                }

                this.totalRevenue = util.convertCurrency(totalRevenue)
                this.billTotal = billTotal
                this.dataAmount = data
            });
        },

        getLineColor(index) {
            const colors = [
                "#AEC6CF", // Pastel Blue
                "#FFB347", // Pastel Orange
                "#77DD77", // Pastel Green
                "#FF6961", // Pastel Red
                "#F49AC2", // Pastel Pink
                "#CFCFC4", // Pastel Gray
                "#FDFD96", // Pastel Yellow
                "#84B6F4", // Pastel Light Blue
                "#C4A7E7", // Pastel Purple
                "#FFD1DC", // Pastel Light Pink
                "#B39EB5", // Pastel Mauve
                "#FFDAC1", // Pastel Peach
                "#B0E57C", // Pastel Lime Green
                "#E0BBE4", // Pastel Lavender
            ];
            return colors[index % colors.length];
        },
        generateScatterData() {
            // Generate random data points for scatter
            return [10, 15, 5, 20, 25, 30, 15, 10].map((value) => Math.random() * value);
        },

        rowClicked(item) {
            this.$router.push(
                '/report/daily/' + this.shopObjectId + '/' + item.dateString
            )
        },
        order(index) {
            let data = this.data[index].order;
            let detail = {};

            if (data != undefined || data != null) {
                detail = {
                    orderNumber: data.orderNumber,
                    orderedAt: moment(data.ordered_at).format("DD MMMM YYYY H:mm:ss"),
                    status: this.$i18n.t("sentSuccessfully"),
                    acceptedAt: moment(data.accepted_at).format("DD MMMM YYYY H:mm:ss"),
                    dueDate: moment(data.dueDate).format("DD MMMM YYYY H:mm:ss"),
                    shippedAt: moment(data.shipped_at).format("DD MMMM YYYY H:mm:ss"),
                };
            }
            this.orderDetail = detail;
        },
        itemsDetail(index) {
            let data = this.data[index].receiptItems;
            let info = [];
            for (let i = 0; i < data.length; i++) {
                let discountBillPerQty = 0;
                let total = 0;
                let discountAmount = 0;

                if (data[i].deleted_at == null) {
                    if (data[i].discountBillPerQty !== null) {
                        discountBillPerQty = data[i].discountBillPerQty * data[i].quantity;
                    }

                    if (data[i].discountAmount !== null) {
                        discountAmount = data[i].discountAmount;
                    }
                    total = data[i].netAmount - discountBillPerQty;
                    let discountTotal = discountAmount + discountBillPerQty;

                    info.push({
                        receiptNumber: this.data[index].receiptNumber,
                        pluCode: data[i].pluCode,
                        pluName: data[i].pluName,
                        orderedOptions: data[i].orderedOptions,
                        quantitySKU: data[i].quantitySKU,
                        price: util.convertCurrency(data[i].price),
                        discountTotal: util.convertCurrency(discountTotal),
                        totalAmount: util.convertCurrency(data[i].totalAmount),
                        netAmount: util.convertCurrency(total),
                    });
                }
            }
            return info;
        },
    },
}
</script>
<style>
.delivery-chart {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.legend {
    width: 250px;
}

.legend ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.legend li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.color-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    display: inline-block;
}


.tablink {
  /* background-color: #555;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 5px;
  font-size: 12px; */
  width: 50%;
}

.tablink:hover {
  /* background-color: #777; */
}

.tabcontent {
  color: white;
  display: none; /* Handled dynamically in Vue */
  padding: 100px 20px;
  height: 100%;
}
.bottom-aligned-container {
    height: 284px; display: flex;justify-content: center; align-items: self-end;
    height: 284px;
    display: flex;
    justify-content: center;
    /* align-items: flex-end; */
    margin: auto; /* Center the container horizontally */
}

.responsive-img {
    max-width: 100%; /* Responsive scaling */
    max-height: 100%;
    object-fit: contain; /* Maintain aspect ratio */
}
@media (max-width: 600px) {
    .bottom-aligned-container {
        height: 170px;
    }
}
</style>