<template>
    <div>
        <CRow class="justify-content-between">
            <CCol lg="12" sm="12" >
                <CRow class="mb-4">
                    <CCol lg="6" sm="6">
                    <div class="card"
                        style="box-shadow: #F29089 0px 0px 0px 2px; color: #F29089; padding: 5px;">
                        <div class="card-body text-center" style="padding: 0px">
                        <div class="text-muted small text-uppercase font-weight-bold"> Totals Revenue
                        </div>
                        <div class="h2 py-1"> {{total.totalGrandTotal}}</div>
                        </div>
                    </div>
                    </CCol>
                    <CCol lg="6" sm="6">
                        <div class="card"
                            style="box-shadow: #B689B2 0px 0px 0px 2px; color: #B689B2 ;padding: 5px;">
                            <div class="card-body text-center" style="padding: 0px">
                            <div class="text-muted small text-uppercase font-weight-bold"> Order total </div>
                            <div class="h2 py-1"> {{ total.totalBills }} </div>
                            </div>
                        </div>
                    </CCol>   
                    <CCol lg="6" sm="6" style="max-height: 150px;overflow-y:auto;"  >
                        <ul class="list-group" style="overflow-y:auto;">
                            <li class="list-group-item "
                                style="    border: 0px solid; padding: 2px;"
                                v-for="(list, index) in data" :key="index">
                                <div
                                    style="border-radius: 5px;margin: 0.0rem 0;box-shadow: 0 0 0 1px #5CBA96;" >
                                    <!-- :style="{ backgroundColor: chartData.datasets[0].backgroundColor[index] }" -->
                                    <div class="row m-1 text-muted small text-uppercase font-weight-bold"
                                        style="padding:2px;">
                                        <div class="col-2 align-items-center">
                                            <img :src="getSalesChannelImage(list.name)" class="img-fluid rounded" width="30px" />
                                        </div>
                                        <div class="col text-left">
                                            <strong>
                                                {{ $t(list.name) }}
                                            </strong>
                                            <div class="m-0 row justify-content-between ">
                                                <p class="m-0"> {{ $t("QTY") }} : {{
                                                    list.bills }}</p>
                                                <p class="m-0"> {{ $t("sales") }} : {{
                                                    list.grandTotal }}</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </CCol>
                    <!-- <CCol lg="1" ></CCol> -->
                    <CCol lg="6" sm="6" style=" align-items: center;" >
                        <CChartDoughnut :datasets="deliveryListChart.datasets"
                            :labels="deliveryListChart.chartLabels" :options="deliveryListChart.chartOptions"
                            style="height: 165px;align-items: center;" />
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import moment from "moment";
  import axios from "@/services/service";
  import report from "@/services/report";
  import permis from "@/util/permission";
  import util from "@/util/util";
  import {  CChartDoughnut } from "@coreui/vue-chartjs";
  
  export default {
    components: {
        CChartDoughnut
    },
    data() {
      return {
        showChartLine: false,
        dataDate: [],
        data: [],
        total : {
            totalBills: 0, 
            totalGrandTotal: 0, 
            totalGpGrandTotal: 0 
        },
        meta_data: {
          last_page: null,
          current_page: 1,
          prev_page_url: null,
          items: 0,
          limit: null,
          itemCount: null,
        },
        loadingButton: true,
        sortOrder: "asc",
        sortdata: "sortBySalesMin",
        sortnum: false,
        sort_value: {
          sortBySalesMin: "sortBySalesMin",
          sortBySalesMax: "sortBySalesMax",
          sortByBillAmountMin: "sortByBillAmountMin",
          sortByBillAmountMax: "sortByBillAmountMax",
        },
        chartData: {
          labels: [], // X-axis labels (dates)
          datasets: [], // Data for each line
        },
  
      };
    },
    computed: {
      ...mapGetters(["shops", "date", "users"]),
      isPermission() {
        return permis.findPermissionRead("report", this.$route.path);
      },
      isExport() {
        return permis.findPermissionExport("report", this.$route.path);
      },
      sortAttr: {
        get() {
          let sortdata = this.sortdata;
          if (sortdata === "sortBySalesMin" || sortdata === "sortBySalesMax") {
            sortdata = "gpGrandTotal";
          }
          if (sortdata === "sortByBillAmountMin" || sortdata === "sortByBillAmountMax") {
            sortdata = "bill";
          }
          return sortdata;
        },
        set(newValue) {
          return newValue;
        },
      },
      uid() {
        let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
        return shop.uid;
      },
      shopObjectId: {
        get() {
          return this.$store.getters.shopObjectId;
        },
        set(newValue) {
          return this.$store.dispatch("setShop", newValue);
        },
      },
      startDate() {
        return moment(String(this.date.start)).format("DD MMMM YYYY");
      },
      endDate() {
        return moment(String(this.date.end)).format("DD MMMM YYYY");
      },
      deliveryListChart() {
        const hasData = this.data && this.data.length > 0;
        return {
            // Chart labels
            chartLabels: hasData
                ? this.data.map(({ name }) => name)
                : [this.$i18n.t("noData")], // Default label for no data
            datasets: [
                {
                    label: hasData
                        ? this.data.map(({ name }) => name)
                        : [this.$i18n.t("noData")], // Default dataset label
                    backgroundColor: hasData
                        ? ["#7ee081", "#ffae55", "#f27b8b", "#56a3f2", "#f990c0", "#ffe47a"] // Data colors
                        : ["#ebedef"], // Neutral color for no data
                    data: hasData
                        ? this.data.map(({ bills }) => bills) // Inner ring values
                        : [1], // Default value for no data
                },
            ],
            chartOptions: {
            legend: { 
              display: false,
              // position: "right"
             },
            responsive: true,
            // height: 170,
            maintainAspectRatio: false,
            cutoutPercentage: 0, // Doughnut hole size
            plugins: {
    
                tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                    return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    },
                },
                },
            },
            },
        };
      },
    },
    created() {
      this.getSalesChannelsSum();
    },
    mounted() {
      this.getSalesChannelsSum();
    },
    methods: {
      getSalesChannelImage(salesChannel) {
        if (salesChannel === 'Grab') {
          return '/img/delivery/grab.png'
        } else if (salesChannel === 'Kiosk') {
          return '/img/delivery/pru_store.png'
        } else if (salesChannel === 'QRorder') {
          return '/img/delivery/pru_store.png'
        } else if (salesChannel === 'Lineman') {
          return '/img/delivery/LINEMAN.png'
        } else if (salesChannel === 'Foodpanda') {
          return '/img/delivery/foodpanda.png'
        } else if (salesChannel === 'Shopeefood') {
          return '/img/delivery/Shopee-food.png'
        } else if (salesChannel === 'Truefood') {
          return '/img/delivery/truefood.png'
        } else if (salesChannel === 'Robinhood') {
          return '/img/delivery/Robinhood.png'
        } else if (salesChannel === '201:SilomPOS Online') {
          return '/img/delivery/pru_store.png'
        } else {
          return '/img/delivery/pru_store.png'
        }
      },
      setSort(value) {
        this.sortdata = value;
        this.getSalesChannelsReport();
      },
      getSalesChannelsSum() {        
        this.loadingButton = false;
        const uid = `${localStorage.getItem("shopsUid")}`;
        const shopObjectId = this.shopObjectId;
        const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
        const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");
  
        const params = {
          shopObjectId: shopObjectId,
          startAt: startAt,
          endAt: endAt,
        };
        const headers = {
          shopObjectId: shopObjectId,
        };
  
        this.loading = true;
        axios({
          url: "/receipt/v1.0/sellchannelsum/" + uid + "/date",
          params: params,
          headers: headers,
          method: "GET",
        })
          .then((res) => {
           let data = res.data.data

           const summaryByName = data.reduce((acc, item) => {
                const existingEntry = acc.find((entry) => entry.name === item.name);
                if (existingEntry) {
                    // Add to existing entry
                    existingEntry.bills += item.bill;
                    existingEntry.grandTotal += item.grandTotal;
                    existingEntry.gpGrandTotal += item.gpGrandTotal;
                } else {
                    // Create a new entry
                    acc.push({
                    name: item.name,
                        bills: item.bill,
                        grandTotal: item.grandTotal,
                        gpGrandTotal: item.gpGrandTotal,
                    });
                }

                return acc;
            }, []);
            
            this.data = summaryByName

            const totals = data.reduce(
                (acc, item) => {
                    acc.totalBills += item.bill;
                    acc.totalGrandTotal += item.grandTotal;
                    acc.totalGpGrandTotal += item.gpGrandTotal;
                    return acc;
                },
                { totalBills: 0, totalGrandTotal: 0, totalGpGrandTotal: 0 }
            );

            this.total = {
                totalBills :  totals.totalBills,
                totalGrandTotal : util.convertCurrency(totals.totalGrandTotal) ,
                totalGpGrandTotal : util.convertCurrency(totals.totalGrandTotal)
            }    
          })
          .catch((error) => {
            console.log(error)
            this.showChartLine = false
            this.loadingButton = true
          });
      },
      
    },
  };
  </script>
  
  <style scoped>
  .chart-container {
    height: 400px;
    position: relative;
  }
  </style>
  