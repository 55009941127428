<template>
  <div class="heatmap-container">
    <v-chart  :option="chartOptions" lazy-update class="heatmap-chart"></v-chart>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import pos from "@/services/local";
import { defineComponent } from "vue";
import { use } from "echarts/core";
import { HeatmapChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";

use([HeatmapChart, GridComponent, TooltipComponent, VisualMapComponent, CanvasRenderer]);

export default defineComponent({
  components: {
    VChart,
  },
  data() {
    return {
      maxBill: 0,
      datalist: [],
    };
  },
  mounted() {
    this.getHourReport();
  },
  async created() {
    await this.getHourReport();
  },
  computed: {
    ...mapGetters(["shops", "date", "users"]),
    chartOptions() {
      return {
        tooltip: {
          position: "top",
          formatter: (params) => {
            if (!params.data) return "No data";
            const days = [this.$i18n.t("sunday"), this.$i18n.t("monday"), this.$i18n.t("tuesday"), this.$i18n.t("wednesday"), this.$i18n.t("thursday"), this.$i18n.t("friday"),this.$i18n.t("saturday")]
            const dayName = days[params.data[1]];
            return `
              <strong>Day:</strong> ${dayName} <br>
              <strong>Hour:</strong> ${params.data[0]} <br>
              <strong>Bills Total:</strong> ${params.data[2]} <br>
           

            `;
          },
        },
        data: this.datalist,
        grid: {
          height: window.innerWidth > 768 ? "80%" : "60%",
          top: "5%",
          left: "0%",
          width: "100%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "12 A.M", "1 A.M", "2 A.M", "3 A.M", "4 A.M", "5 A.M", "6 A.M", "7 A.M",
            "8 A.M", "9 A.M", "10 A.M", "11 A.M", "12 P.M", "1 P.M", "2 P.M", "3 P.M", 
            "4 P.M", "5 P.M", "6 P.M", "7 P.M", "8 P.M", "9 P.M", "10 P.M", "11 P.M",
          ],
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: "category",
          data: [this.$i18n.t("sunday"), this.$i18n.t("monday"), this.$i18n.t("tuesday"), this.$i18n.t("wednesday"), this.$i18n.t("thursday"), this.$i18n.t("friday"),this.$i18n.t("saturday")],
          splitArea: {
            show: true,
          },
        },
        visualMap: {
          min: 0,
          max: this.maxBill || 1,
          calculable: true,
          orient: "horizontal",
          left: "center",
          top:  "85%",
          bottom: "0%",
            // color: ["#FFFFFF", "#d8f9db", "#51af65", "#45734c"],
          // ["#FFFFFF", "#ffdd82","#cfd06c", "#94c063", "#51af65"],
          inRange: {
            color: ["#FFFFFF","#cfd06c", "#94c063", "#51af65"],
          },
          outOfRange: {
            color: ["#f5f5f5"],
          },
        },
        series: [
          {
            name: "Hourly Sales Report",
            type: "heatmap",
            data: this.datalist,
            label: {
              show: true,
              fontSize: window.innerWidth > 768 ? 12 : 10,
              color: "#000",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 20,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    getHourReport() {
      const shopObjectId = localStorage.getItem("shopObjectId");
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

      const params = {
        shopObjectId,
        startAt,
        endAt,
        channel: "101",
      };
      const headers = {
        shopObjectId,
      };

      pos({
        url: "/api/v3.0/sellingchannel/statistic/orderfrequency",
        params,
        headers,
        method: "get",
      })
        .then((res) => {
          const rawData = res.data.data || [];
          if (!rawData.length) {
            this.datalist = [];
            this.maxBill = 1;
            return;
          }

          const daysMap = { "1": 0, "2": 1, "3": 2, "4": 3, "5": 4, "6": 5, "7": 6 };
          const aggregatedData = rawData.reduce((acc, curr) => {
            const key = `${curr.dayOfWeek}-${curr.hourOfDay}`;
              if (!acc[key]) acc[key] = { ...curr, bill: 0 };
              acc[key].bill += curr.bill;
              return acc;
            }, {});

          this.datalist = Object.values(aggregatedData).map((item) => [
            parseInt(item.hourOfDay, 10),
            daysMap[item.dayOfWeek],
            item.bill,
          ]);

          this.maxBill = Math.max(...Object.values(aggregatedData).map((item) => item.bill));
              
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
});
</script>

<style scoped>
.heatmap-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.heatmap-chart {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .heatmap-container {
    padding: 10px;
  }

  .heatmap-chart {
    height: 250px;
    /* width: 450px; */
  }
}

@media (min-width: 1200px) {
  .heatmap-chart {
    /* width: 500px; */
    height: 380px;
  }
}
</style>
